import React, { useState, useEffect } from "react";
import auth, { database } from "./Firebaseauth";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import db from "./Firebase";
import firebase from "firebase";
import { Grid } from "react-loader-spinner";

function Login() {
  const navigate = useNavigate();

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        database
          .collection("analytics")
          .doc(user.uid)
          .update({
            vgcreativeslogin: firebase.firestore.FieldValue.increment(1),
          })
          .then((done) => {
            navigate(`/mediadownloader/home/${user.uid}`);
          });
      } else {
      }
    });
  }, []);

  const logintapped = () => {
    if (email != "" && password != "") {
      setIsLoading(true);
      auth
        .signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          var user = userCredential.user;
          if (!userCredential.exists) {
            database
              .collection("users")
              .doc(user.uid)
              .get()
              .then((existingser) => {
                if (
                  existingser.data().canusevgcreatives === true ||
                  existingser.data().usertype === "VG Creatives"
                ) {
                  var fullname =
                    existingser.data().firstname +
                    " " +
                    existingser.data().lastname;

                  db.collection("users")
                    .doc(user.uid)
                    .get()
                    .then((usercurrentdata) => {
                      if (!usercurrentdata.exists) {
                        db.collection("users")
                          .doc(user.uid)
                          .set({
                            name: fullname,
                            logourl: "",
                            noofdownloadsleft: 100,
                            totaldownloadsallocated: 100,
                            tagline: "",
                            tagline2: "",
                            tagline3: "",
                            isadmin: false,
                          })
                          .then((done) => {
                            database
                              .collection("analytics")
                              .doc(user.user.uid)
                              .update({
                                vgcreativeslogin:
                                  firebase.firestore.FieldValue.increment(1),
                              });
                            setIsLoading(false);
                            navigate(`/mediadownloader/home/${user.uid}`);
                          });
                      } else {
                        database
                          .collection("analytics")
                          .doc(user.user.uid)
                          .update({
                            vgcreativeslogin:
                              firebase.firestore.FieldValue.increment(1),
                          });
                        setIsLoading(false);
                        navigate(`/mediadownloader/home/${user.uid}`);
                      }
                    });
                } else {
                  alert("You are not authorized to use this tool");
                  setIsLoading(false);
                  auth.signOut();
                  navigate("/mediadownloader");
                }
              });
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          alert("Login Failed");
        });
    }
  };
  return (
    <div className="login">
      <div className="part">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fimages%2F04.png?alt=media&token=322acb3f-ddc0-4159-a542-e080e16a4ed6"
          style={{ width: 200 }}
        />
        <h3>VG CREATIVES</h3>
      </div>

      <div className="loginregion">
        <h4>Login to continue</h4>
        <div>
          <h6>Enter your Email</h6>
          <input
            type="email"
            placeholder="Enter Email"
            onChange={(e) => setemail(e.target.value)}
          />
        </div>
        <div>
          <h6>Enter your Password</h6>
          <input
            type="password"
            placeholder="Enter Password"
            onChange={(e) => setpassword(e.target.value)}
          />
        </div>
        <button onClick={logintapped}>LOGIN</button>
      </div>
      {isLoading && (
        <div className="loadingcontainer">
          <Grid height="100" width="100" color="#121e1e" ariaLabel="loading" />
        </div>
      )}
    </div>
  );
}

export default Login;
